<template>
  <app-module-view>
    <template slot="body">
      <template v-if="dataLoaded">
        <section class="m-b-10">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-right m-r-5">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="save"
                >
                  <i class="fa fa-save "></i> {{ $t('buttons.save') }}
                </button>
                <app-button-close route-name="don_npa_list"></app-button-close>
              </div>
            </div>
          </div>
        </section>
        <div class="card">
          <div class="card-body">
            <section class="content form-horizontal">
              <div class="box box-info">
                <div class="box-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <app-select
                        v-model="config.nativePerfCount"
                        :options="nativePerfCountOptions"
                        :noEmptyValue="true"
                        :label="$t('don.npa.native_perf_count')"
                      >
                      </app-select>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </template>
      <div v-else>
        <app-preloader></app-preloader>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import Select from '../form/select/Select'
import ButtonClose from '../shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import DonNativePerfConfig from '../../model/DonNativePerfConfig'
import Preloader from '../preloader/Preloader'

export default {
  name: 'NativePerfConfig',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      config: this._.cloneDeep(DonNativePerfConfig),
      nativePerfCountOptions: [
        { id: 0, title: 0 },
        { id: 1, title: 1 },
        { id: 2, title: 2 },
        { id: 3, title: 3 },
        { id: 4, title: 4 },
        { id: 5, title: 5 },
        { id: 6, title: 6 }
      ]
    }
  },
  components: {
    appModuleView: ModuleView,
    appSelect: Select,
    appButtonClose: ButtonClose,
    appPreloader: Preloader
  },
  methods: {
    getConfig () {
      this.$store.dispatch('nativePerfConfig/fetchConfig')
        .then(() => {
          this.config = this.$store.getters['nativePerfConfig/detail']
          this.dataLoaded = true
        })
    },
    async save () {
      this.$store.dispatch('nativePerfConfig/update', this.config)
        .then(() => {
          this.config = this.$store.getters['nativePerfConfig/fetchConfig']
          if (this.$store.getters['nativePerfConfig/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['nativePerfConfig/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getConfig()
  }
}
</script>
